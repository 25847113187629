import { Controller } from "@hotwired/stimulus";
import axios from 'axios';

class CustomMessageSignatureController extends Controller {
  static targets = ['body', 'enabledSignature'];

  showModal(event) {
    event.preventDefault();

    const modal = document.getElementById('modal_form');
    const content = modal.querySelector('.modal-content');
    const venueId = document.querySelector('#conversations .selected a')?.dataset?.venue;
    const url = `/admin/custom_message_signature?venue_id=${venueId}`;

    axios.get(url).then((response) => {
      content.innerHTML = response.data;
    });

    $(modal).modal('show');
  }

  selectVenue(event) {
    const target = event.target;
    const url = `/admin/custom_message_signature.json?venue_id=${target.value}`;
    const body = this.bodyTarget;
    const enabledSignatureCheckbox = this.enabledSignatureTarget;

    if (target.value == 'all-venues') {
      body.value = '';
      enabledSignatureCheckbox.checked = false

      return null;
    }

    axios.get(url).then((response) => {
      body.value = response.data.body;
      enabledSignatureCheckbox.checked = response.data.enabled;
    });
  }
}

export default CustomMessageSignatureController;
