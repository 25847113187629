import { Controller } from "@hotwired/stimulus";
import axios from 'axios';
import FileManager from "src/nextgen/pages/file_manager";

export default class extends Controller {
  static targets = ['submit', 'file'];

  connect() {
    this.submitTarget.disabled = this.fileTarget.files.length == 0;

    this.fileTarget.addEventListener('change', (event)=> { 
      this.submitTarget.disabled = event.target.files.length == 0;
    });

    const parentController = document.querySelector('#file_manager_modal');

    parentController.addEventListener('neo_modal:success', () => {
      this.submitTarget.disabled = true;

      const currentPath = this.element.dataset['currentPath'];

      let docType = $('input[name="filter_attachments_dates"]:checked').val();
      let venue = $('select[name="venue_select"] option:selected').val();
      let newPath = currentPath.replace('/file_managers?', '/file_managers/list?');
      newPath += `&doc_type_filter=${docType || ''}&venue_name=${venue}`;

      axios.get(newPath).then((content) => {
        $('#file_manager_modal .modal-body').empty().append(content.data);
        const modalElement = document.querySelector('#file_manager_modal');
        const modalController = this.application.getControllerForElementAndIdentifier(modalElement, 'neo_modal');
        // because we manually rewriting content of modal on line 28
        // we need to manually reconnect controller to the modal element
        modalController.connect();
        new FileManager().init();
      });
    });
  }

  addReview(e) {
    e.preventDefault();
    this.reviewAction(e.target, 'post');
  }

  removeReview(e) {
    e.preventDefault();
    this.reviewAction(e.target, 'delete');
  }

  reviewAction(button, method) {
    const url = button.dataset.url;
    const cellEl = button.closest('td');

    axios({ method: method, url: url }).then((response) => {
      cellEl.innerHTML = response.data.content;
    }).catch(() => Helper.flash_message('error', 'Something went wrong'))
  }
}
