import React, { useEffect, useRef } from "react";
import moment from "moment/moment";

export default ({ eventDate, minEventDate, maxEventDate, onChange }) => {
  const ref = useRef();

  useEffect(() => {
    $(ref.current).datepicker({
      format: 'D, dd M yyyy',
      autoclose: true,
      startDate: minEventDate,
      endDate: maxEventDate,
      weekStart: 1,
      container: '.booking-wrapper',
      orientation: "auto auto"
    })
  }, [])

  useEffect(() => {
    $(ref.current).datepicker('update', eventDate)
  }, [eventDate]);

  useEffect(() => {
    $(ref.current).off('changeDate');
    $(ref.current).on('changeDate', (e) => {
      const val = e.date;
      const date = moment(val).format('ddd, DD MMM YYYY');
      onChange(date);
    });
  }, [onChange]);

  return (
    <div className="form-group string optional event_date vert-offset-bottom-10">
      <label className="string optional control-label">Date</label>
      <input type='text'
             ref={ref}
             readOnly={true}
             className='string optional form-control'/>
    </div>
  )
}