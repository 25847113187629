class User {
  constructor() { }

  start() {
    if ($('.admin_users_edit').length > 0 || $('.admin_users_update').length > 0) {
      const partnerCheckbox = $('#user_is_partner');
      const editPartnerBlock = $('.edit-partner-profile-block');

      if (partnerCheckbox.prop('checked')) {
        editPartnerBlock.show();
      }

      partnerCheckbox.change((event) => {
        editPartnerBlock.toggle($(event.currentTarget).checked);
      });
    }

    if ($('body.admin_users_index').length < 1) {
      return;
    }

    this.toDataTable();

    $('#users_filter select').on('change', () => this.reloadDataDebounced());
    $('#users_filter').on('submit', (event) => event.preventDefault());

    $('#users_filter input').on('keyup', (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      } else {
        this.reloadDataDebounced();
      }
    });
  }

  toDataTable() {
    const users_table_params = {
      order: [[1, 'asc']],
      processing: true,
      serverSide: true,
      searching: false,
      lengthChange: false,
      pageLength: 30,
      ajax: this.usersURL,
      pagingType: 'full_numbers',

      fnInitComplete: (oSettings) => {
        window.assignDatatableAutoscroll(oSettings);
      },

      fnDrawCallback: (oSettings) => {
        const $table_wrapper = $(oSettings.nTableWrapper);
        const $table = $table_wrapper.find('table');
        const titles = _.map($table.find('thead th'), (x, y) => x.innerText);
        const show = oSettings._iDisplayLength <= oSettings.fnRecordsDisplay();

        $table_wrapper.find('.dataTables_paginate').toggle(show);
        $table_wrapper.find('.dataTables_info').toggle(show);

        _.each($table.find('tr'), (row) => {
          _.each($(row).find('td'), (cell, index) => {
            $(cell).attr('data-title', titles[index]);
          });
        });
      }
    };

    $('#users-table.admin_side').dataTable($.extend({}, users_table_params, {}), localStorage.removeItem('calendar_parameters'));
  }

  reloadData(changePage) {
    const ajax = $('#users-table').DataTable().ajax;
          ajax.url(this.usersURL);
          ajax.reload(null, changePage);
  };

  reloadDataDebounced() {
    _.debounce(() => this.reloadData(true), 250)();
  }

  get usersURL() {
    return $('#users-table').data('source') + '?' + $('#users_filter > form').serialize();
  }
}

export default User;
