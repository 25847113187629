import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['continue', 'answer'];

  connect() {
    this.value = 0;

    document.addEventListener('updateUploadStatus', (event) => {
      this.updateValue(event.detail);
    });

    function isElementInViewport(el) {
      if (typeof jQuery === "function" && el instanceof jQuery) {
          el = el[0];
      }

      var rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement
              .clientWidth) /* or $(window).width() */
      );
    }

    $(".required").on('focus', function() {
      var container
      container =
        !$(this).is('select')
          ? $(this).is('textarea') ? $(this).parent().parent().parent() : $(this).parent().parent().parent().parent()
          : $(this).parent().parent();
      if (!isElementInViewport(container)) {
        container[0].scrollIntoView();
      }
    });
  }

  updateValue(delta) {
    this.value += delta;
    this.continueTarget.disabled = (this.value > 0);
  }

  onSelectOption(e) {
    const question = e.target.closest('.question');
    const answersTriggerChild = question.dataset.answersTriggerChild;

    if (answersTriggerChild === '') return;

    const parsedAnswers = answersTriggerChild.split(',');

    this.answerTargets
      .filter((el) => el.dataset.parentId == question.dataset.id)
      .map((item) => {
        let disabled = null;
        let childInputs = item.querySelectorAll('input, select, textarea');

        if (parsedAnswers.includes(e.target.value)) {
          disabled = false;
          item.classList.remove('hide');
        } else {
          disabled = true;
          item.classList.add('hide');
        }

        [...childInputs].map((el) => el.disabled = disabled);
      });
  }
}
