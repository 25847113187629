import { Controller } from "@hotwired/stimulus";

export default class DatepickerController extends Controller {
  static values = {
    onChangeDate: String
  };

  connect() {
    const self = this.element;
    const format = self.dataset.format || 'D, dd M yyyy'
    const autoclose = self.dataset.autoclose || true
    const container = self.dataset.container
    const onChangeDateCallback = window[this.onChangeDateValue];

    const datepicker = $(self).datepicker({
      format: format,
      autoclose: autoclose,
      container: container
    });

    if (onChangeDateCallback) datepicker.on('changeDate', onChangeDateCallback);
  }
}
