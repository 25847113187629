import React, { useState, useRef } from 'react';
import Form from "./Form";
import Table from "../components/Table/Table";
import axios from "axios";
import ConfirmButton from "./ConfirmButton";

export default ({ location }) => {
  const params = new URLSearchParams(location.search)
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({});
  const [venueId, setVenueId] = useState(params.get('venue_id'));
  const [creationInProgress, setCreationInProgress] = useState(false);

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

  const fetchData = (staffRotaId) => {
    axios
      .get('/admin/staff_items.json', { params: { staff_rota_id: staffRotaId } })
      .then((response) => {
        setData(response.data.staffItems)
        setTotal(response.data.total)
        executeScroll();
        setTimeout(() => setCreationInProgress(false), 100);
      })
      .catch((error) => {
        console.log(error);
        setCreationInProgress(false);
      });
  }

  const handleUpdateTable = () => {
    fetchData($('input[name=staff_rota_id]').val());
  }

  return (
    <>
      <div className='row'>
        <div className='col-lg-12'>
          <h2 style={{ marginTop: 0, marginLeft: 0 }}>Create Staff Rota</h2>
        </div>
      </div>

      <Form
        onCreated={fetchData}
        venueId={venueId}
        creationInProgress={creationInProgress}
        setCreationInProgress={setCreationInProgress}
      />

      {(data || []).length > 0 &&
        <div ref={myRef}>
          <Table data={data} total={total} onUpdated={handleUpdateTable} creationProcess={true}/>
          {!creationInProgress && <div className='text-right'><ConfirmButton venueId={venueId}/></div>}
        </div>
      }
    </>
  )
};