import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import DropDown from "./DropDown";

const pendingUsers = [
  { title: 'Excluding Pending User', value: true },
  { title: 'Including Pending User', value: false },
];

export default ({ onChanged, options }) => {
  const selectedOption = String(options.excludedPendingUser) === 'false' ? pendingUsers[1] : pendingUsers[0]
  const [pendingUser, setPendingUser] = useState(selectedOption);

  useEffect(() => {
    const params = pendingUser ? { excludedPendingUser: pendingUser.value } : null;
    onChanged({ value: pendingUser, pending: false, params });
  }, [pendingUser]);

  return (
    <div className={classNames(['filter-block filter-pending-users'])}>
      <label htmlFor="filter-pending-users">Bookings:</label>
      <div className='input-box input-box-react-select'>
        <DropDown
          value={pendingUser}
          options={pendingUsers}
          onChange={setPendingUser}
          getOptionLabel={opt => opt.title}
          getOptionValue={opt => opt.value}
        />
      </div>
    </div>
  )
};
