import * as Turbo from "@hotwired/turbo";

Turbo.start();
Turbo.navigator.delegate.stop()

window.Turbo = Turbo;

import '../src/pages/index'
import "../controllers"
import '../src/nextgen/Index'

