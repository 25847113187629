import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from "./Form";
import axios from "axios";
import ErrorBoundary from "../ErrorBoundary";

export default ({ show, setShow, onCompleted }) => {
  const [reportType, setReportType] = useState(null)
  const [searchFilters, setSearchFilters] = useState({})
  const [startDate, setStartDate] = useState()
  const [weekDays, setWeekDays] = useState([])
  const [disabledConfirm, setDisabledConfirm] = useState(false)
  const [exportType, setExportType] = useState()
  const [reportTime, setReportTime] = useState()
  const [validationErrors, setValidationErrors] = useState({})
  const [availableUsers, setAvailableUsers] = useState([])
  const [ccUsers, setCcUsers] = useState([])
  const [selectedVenueId, setSelectedVenueId] = useState()
  const [showCcUsersSelector, setShowCcUsersSelector] = useState(false)
  const [currentUser, setCurrentUser] = useState()
  const [reportInterval, setReportInterval] = useState()
  // const [errors, setErrors] = useState(null);

  const defaultWeekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'].map((day, index) => {
    return { label: day, value: index }
  })

  useEffect(() => {
    if (show === false) {
      setReportType(undefined);
      setSearchFilters({});
      setWeekDays(defaultWeekDays)
      setReportTime(moment('16:00', 'HH:mm'))
      setValidationErrors({})
      setAvailableUsers([])
      setCcUsers(currentUser ? [currentUser] : [])
      setSelectedVenueId()
      setReportInterval('today')
      setStartDate(new Date)
    }
  }, [show])

  const handleCreate = () => {
    setDisabledConfirm(true);

    const formData = {
      report_schedule: {
        report: reportType.key,
        filters: searchFilters,
        start_date: startDate,
        week_days: weekDays.map(item => item.value),
        export_type: exportType,
        report_time: reportTime,
        cc_users: ccUsers.map(user => delete user['readOnly?'] && user),
        report_interval: reportInterval
      }
    };

    axios
      .post('/admin/reports/schedules.json', formData)
      .then((response) => {
        setShow(false);
        onCompleted();
        setDisabledConfirm(false)
      })
      .catch((error) => {
        if(error?.response?.data?.errors) {
          setValidationErrors(error.response.data.errors)
        }
        setDisabledConfirm(false)
      });
  }

  const handleFiltersChange = (newValue) => {
    const newVenueId = newValue['venueId']
    const isNotVenueGroup = (typeof newVenueId === 'number')
    setSelectedVenueId(newVenueId)
    setShowCcUsersSelector(isNotVenueGroup)
    if(!isNotVenueGroup) {
      setCcUsers([])
      setAvailableUsers([])
    }
    if(isNotVenueGroup && newVenueId !== selectedVenueId) {
      setAvailableUsers([])
      setCcUsers(currentUser ? [currentUser] : [])
      axios
        .get('/admin/reports/schedules/venue_users.json', {params: { venue_id: newVenueId }})
        .then((response) => {
          setAvailableUsers(response?.data?.venueUsers || [])
          if(!currentUser && response?.data?.currentUser) {
            setCurrentUser(response.data.currentUser)
            setCcUsers([response.data.currentUser])
          }
        })
    }
    setSearchFilters(newValue)
  }

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>New Report Schedule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorBoundary>
          <Form
            reportType={reportType}
            setReportType={setReportType}
            searchFilters={searchFilters}
            handleFiltersChange={handleFiltersChange}
            startDate={startDate}
            setStartDate={setStartDate}
            weekDays={weekDays}
            setWeekDays={setWeekDays}
            exportType={exportType}
            setExportType={setExportType}
            reportTime={reportTime}
            setReportTime={setReportTime}
            validationErrors={validationErrors}
            ccUsers={ccUsers}
            availableUsers={availableUsers}
            setCcUsers={setCcUsers}
            showCcUsersSelector={showCcUsersSelector}
            reportInterval={reportInterval}
            setReportInterval={setReportInterval}
            currentUser={currentUser}
          />
        </ErrorBoundary>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button className='btn-primary' disabled={disabledConfirm} onClick={handleCreate}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  )
};
