import OrdersDataTable from './ordersDataTable';

class OrdersTable {
  constructor() {
    this.$adminOrdersModal = $('#admin_orders_modal');
    this.orderDatatable = new OrdersDataTable;
  }

  start() {
    if ($('body.account_orders_index, body.account_orders_show, body.admin_orders_show, body.admin_orders_index, body.account_bookings_index, body.admin_bookings_index,' + 'body.admin_offline_orders_index, body.admin_orders_internal_notes, body.account_offline_payments_index, body.admin_offline_payments_index').length < 1) {
      return;
    }

    $('#filter_statuses_internal').on('change', () => {
      if ($('#filter_statuses_internal').prop('checked') && !$('#filter_statuses_accepted').prop('checked')) {
        $('#filter_statuses_accepted').click();
      }
    });

    this.toDataTable();
    this.prepareDataTableColumns();

    $(document).on('change', '.checkbox-approval > input', (event) => {
      const approvalBlocks = document.querySelectorAll('form#new_approval .checkboxes-approval');
      const disabled = Array.from(approvalBlocks).some((approvalBlock) => {
        return approvalBlock.querySelectorAll('input[type=checkbox]:not(:checked)').length > 0;
      });

      document.querySelector('form#new_approval input[type=submit]').disabled = disabled;
    });

    $('#orders-table').on('draw.dt', this.onReDraw);

    $('#new_filter .filter_statuses input[type=checkbox]').on('change', (event) => {
      this.prepareDataTableColumns();
      this.reloadData(true);
    });

    $(document).on('click', '.all_none_filter a.all', (event) => {
      this.changeAllFilterStatuses(event, true);
    });

    $(document).on('click', '.all_none_filter a.none', (event) => {
      this.changeAllFilterStatuses(event, false);
    });

    $('#new_filter .filter_time_period input[type=radio]').on('change', () => {
      this.reloadData(true);

      const checkedValue = $('#new_filter .filter_time_period input[type=radio]:checked').val();

      $('.custom_dates').toggleClass('hidden', checkedValue !== 'custom');
    });

    $('#new_filter .filter_modification_status input[type=radio]').on('change', () => {
      this.prepareDataTableColumns();
      this.reloadData(true);
    });

    this.onFilterChange();
    this.onOpenModal();
    this.onModalSubmit();

    $(document).on('change', '.offline_order_detail_paid', (event) => {
      const $form = $(event.currentTarget).closest('form');

      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: $form.serialize(),

        success: () => {
          Helper.flash_message('success', 'Successfully updated');
        },

        error: () => {
          Helper.flash_message('error', 'Failed');
        }
      });
    });

    $(document).on('click', 'input.discount_group[type=radio]', (event) => {
      const $target = $(event.currentTarget);

      $target.parent().siblings('.vat').find('input[type=checkbox]').prop('checked', $target.data('vat'));
    });
  }

  init() {}

  toDataTable() {
    const failedPaymentFilter = document.getElementById('filter_modification_status_failed_payment');
    const failedPaymentFilterChecked =  failedPaymentFilter && failedPaymentFilter.checked
    const adminFirstColumnSortDir = (this.isCheckedStatus(['pending', 'pending_user']) || failedPaymentFilterChecked) ? 'asc' : 'desc'
    const accountColumnSortDir = failedPaymentFilterChecked ? 'asc' : 'desc'

    this.commonDataTable($('#orders-table.account_orders_table'), accountColumnSortDir, [2, 4, 5, 6, 7, 8]);
    this.commonDataTable($('#orders-table.account_bookings_table'), 'asc', [3, 4, 5]);
    this.commonDataTable($('#orders-table.admin_bookings_table'), 'asc', [2, 3, 4]);
    this.commonDataTable($('#orders-table.admin_offline_orders_table'), 'asc', [2, 3, 4, 5, 6, 7, 8]);

    const defaultParams = (new OrdersDataTable).defaultParams(this.ordersURL);

    $('#orders-table.admin_orders_table').dataTable({
      ...defaultParams,
      order: [[0, adminFirstColumnSortDir]],
      columnDefs: [
        {
          targets: [3, 4, 5, 6, 7, 8],
          orderable: false
        },
        {
          targets: [4, 5, 6],
          width: '10%'
        }
      ]
    });
  }

  onFilterChange() {
    $('#new_filter .filter_start_date, #new_filter .filter_end_date, #new_filter .filter_venue_id select, #new_filter .filter_status_offline_orders input[type=radio]').on('change', (event) => {
      const $target = $(event.currentTarget);

      if ($('#new_filter .filter_customer_id select').length > 0) {
        $.ajax({
          url: '/admin/bookings/' + $target.val() + '/customers',
          success: (data) => {
            data = $.map(data, (v) => {
              return '<option value=' + v[0] + '>' + v[1] + '</option>';
            });

            $('#new_filter .filter_customer_id select').children().remove();
            $('#new_filter .filter_customer_id select').append(data);

            this.reloadData(true);
          },

          error: () => {
            this.reloadData(true);
          }
        });
      } else {
        this.reloadData(true);
      }
    });

    $('#new_filter .filter_customer_id select').on('change', () => {
      this.reloadData(true);
    });

    $('#new_filter .filter_terms input').on('keyup', _.debounce(() => {
      this.reloadData(true);
    }, 250));
  }

  onOpenModal() {
    $(document).on('click', '.dropdown-menu li a.open_order_modal', (event) => {
      event.preventDefault();

      const $target = $(event.target);
      if ($target.parent('li').hasClass('disabled')) return;

      this.showModal($target.attr('href'));
    });

    $(document).on('click', 'a.btn.open_order_modal', (event) => {
      event.preventDefault();

      this.showModal($(event.target).attr('href'));
    });
  }

  onModalSubmit() {
    $(document).on('click', '#admin_orders_modal form [type=submit]', (event) => {
      event.preventDefault();

      const $target = $(event.currentTarget);

      $target.prop('disabled', true);

      const $form = $target.closest('form');

      $.ajax({
        type: 'POST',
        url: $form.attr('action'),
        data: $form.serialize(),

        success: () => {
          const reload = this.$adminOrdersModal.find('form').data('reload');
          const reloadPath = this.$adminOrdersModal.find('form').data('reload-path');

          this.$adminOrdersModal.modal('hide');

          if (reload) {
            if ($('#orders-table').length > 0) {
              this.reloadData(false);
            } else {
              if (reloadPath) {
                localStorage.showNotification = 'showNotification';
              }

              location.replace(reloadPath || location.href) || location.reload();
            }
          }
        },

        error: (data) => {
          this.$adminOrdersModal.find('.modal-content').empty().append(data.responseText);
        }
      });
    });
  }

  commonDataTable($element, direction, targets) {
    const defaultParams = (new OrdersDataTable).defaultParams(this.ordersURL);

    $element.dataTable({
      ...defaultParams,
      order: [[0, direction]],
      columnDefs: [
        {
          targets: targets,
          orderable: false
        }
      ],
      language: {
        emptyTable: 'There is no data available. Please change your filters and try again.'
      }
    })
  }

  onReDraw() {
    const accountBookingsTable = $('#orders-table.account_bookings_table').length > 0;
    const accountOrdersTable = $('#orders-table.account_orders_table').length > 0;
    const adminBookingsTable = $('#orders-table.admin_bookings_table').length > 0;

    let venueColumnIndex = 2;

    if (accountBookingsTable || adminBookingsTable || accountOrdersTable) {
      venueColumnIndex = 1;
    }

    if (!(accountOrdersTable || adminBookingsTable)) {
      const isVisible = $('#filter_venue_id').val() === '';
      const hasMoreThanTwoOptions = $('#filter_venue_id').find('option').length > 2;

      $('#orders-table').DataTable().column(venueColumnIndex).visible(isVisible) && hasMoreThanTwoOptions;
    }
  }

  reloadData(changePage) {
    const ajax = $('#orders-table').DataTable().ajax;
          ajax.url(this.ordersURL);
          ajax.reload(null, changePage);
  };

  changeAllFilterStatuses(event, status) {
    event.preventDefault();

    $(event.currentTarget).closest('.all_none_filter').find('input[type=checkbox]').prop('checked', status);
    this.prepareDataTableColumns();
    this.reloadData(true);
  };

  showModal(url) {
    $.get(url, (data) => {
      this.$adminOrdersModal.find('.modal-content').empty().append(data);
      this.$adminOrdersModal.find('.modal-dialog').css('width', '');

      const form = this.$adminOrdersModal.find('form');

      if ((form.length > 0) && (form.data('modal-width') !== void 0)) {
        this.$adminOrdersModal.find('.modal-dialog').css('width', form.data('modal-width'));
      }

      this.$adminOrdersModal.modal('show');
    });
  }

  closeModalAndRefreshPage() {
    this.$adminOrdersModal.find('.modal-content').empty();
    this.$adminOrdersModal.modal('hide');

    this.reloadData(true);
  }

  get ordersURL() {
    const emailURL = new RegExp('[\?&]email=([^&#]*)').exec(window.location.href);
    let emailParameter;

    if (emailURL) {
      emailParameter = '&filter%5Bemail=' + emailURL[1];
    }

    let url = $('#orders-table').data('source') + '?' + $('form#new_filter').serialize();

    if (emailParameter) {
      url = url + emailParameter;
    }

    return url;
  }

  prepareDataTableColumns() {
    const identifiers = ['admin', 'account'];
    const columnIndexes = [0];

    identifiers.map((identifier) => {
      const table = $(`table.${identifier}_orders_table`);

      if (table.length === 0) return;

      const dataTable = table.DataTable();

      columnIndexes.map((index) => {
        this.onChangeFilterUpdateColumnTitle(identifier, dataTable, index)
      });
    });
  }

  onChangeFilterUpdateColumnTitle(identifier, dataTable, columnIndex) {
    const dataColumn = dataTable.column(columnIndex).header();
    const [dataOrderDir] = dataTable.order()[columnIndex] || [null];
    const failedPaymentFilter = document.getElementById('filter_modification_status_failed_payment');
    const sentRequestFilter = document.getElementById('filter_modification_status_sent_request');

    if (columnIndex === 0) {
      if (identifier === 'admin' && this.isCheckedStatus(['pending', 'pending_user'])) {
        dataColumn.innerText = 'Date of first session';
        if (dataOrderDir !== 'asc') dataTable.order([columnIndex, 'asc']);
        this.switchTimePeriodPresentAndUpcomingFilter(true);
      } else if (failedPaymentFilter.checked) {
        dataColumn.innerText = 'Date of failed payment';
        if (dataOrderDir !== 'asc') dataTable.order([columnIndex, 'asc']);
        this.switchTimePeriodPresentAndUpcomingFilter(false);
      } else if (sentRequestFilter.checked) {
        dataColumn.innerText = 'Date of amendment request';
      } else if (this.isCheckedStatus(['cancellation_requested'])) {
        dataColumn.innerText = 'Date of cancellation request';
      } else {
        dataColumn.innerText = 'Date of request';
        if (dataOrderDir !== 'desc') dataTable.order([columnIndex, 'desc']);
        this.switchTimePeriodPresentAndUpcomingFilter(false);
      }
    }
  }

  isCheckedStatus(values) {
    const checkedCheckboxes = $('#new_filter .filter_statuses input[type=checkbox]:checked');
    const statuses = Array.from(checkedCheckboxes, status => status.value);
    const excludedStatuses = new Set(statuses.filter(x => !values.includes(x)));

    return excludedStatuses.size === 0 && statuses.length > 0;
  }

  switchTimePeriodPresentAndUpcomingFilter(show) {
    const filterTimePeriodUpcoming = $('#filter_time_period_present_and_upcoming');
    const timePeriodAll = $('.filter_time_period #filter_time_period_all_times');

    if (show) return filterTimePeriodUpcoming.parents('.radio').show();
    filterTimePeriodUpcoming.parents('.radio').hide();
    if (filterTimePeriodUpcoming.is(':checked')) timePeriodAll.prop('checked', true);
  }
}

export default OrdersTable;
