import React from 'react';

class ValidationError extends React.Component {
  render() {
    if(!this.props.error) { return null }

    return (
      <div className='validation-error'>
        {this.props.message || this.props.error}
      </div>
    )
  }
}

export default ValidationError
