import './Customers/index';
import './special_dates/index';
import './schedules/form';
import './booking_options/sort';
import './extras/sort';
import './venues/settings';
import './venues/sort';
import './questions/Index';
import './settings/Index';
import './settings/logo_section/index';
import './app_features/form';
import './booking_closed_hours/booking_closed_hours';
import './orders/resend_message';
import './orders/user_to_action_reminder';
import './orders/order_message_rules';
import './orders/show_initiated_message_modal';
import './order_venue_notes/index';
import './calendars/index';
import './Facilities/index';
import './member';
import './documents/index';
import './documents/form';
import './areas/index';
import './orders/resend_failed_payment_email';
import './orders/overdue_payment_reminder_email';
import './conversations/custom_message';
import './OfflineSchedules/index';
import './InternalNotes/index';
import './users/index'
import './OfflinePayments/form';
import './special_dates/form';
import './venues/future_prices_form';

import Facility from './migrated/facility';
import HelpCentreDocument from './migrated/help_centre_document';
import InfoPage from './migrated/info_page';
import MembershipPlan from './migrated/membership_plan';
import OrdersTable from './orders/table';
import Schedule from './migrated/schedule';
import User from './migrated/user';
import Vats from './migrated/vats';
import Venue from './migrated/venue';
import VenueGroups from './migrated/venue_groups';
import ApplicationSettings from './ApplicationSettings/index';
import OfflinePayments from "./OfflinePayments";

import Invitation from './users/Invitation';
import VenueAdminInvitation from './VenueAdmins/Invitation';

document.addEventListener('DOMContentLoaded', () => {
  window.SchoolHire = {};
  window.SchoolHire.admin = {};

  new Facility().start();
  new HelpCentreDocument().start();
  new InfoPage().start();
  new MembershipPlan().start();
  new OrdersTable().start();
  new Schedule().start();

  const user = new User()
  user.start();

  window.refreshUsers = () => user.reloadData(true);

  new Vats().start();
  new Venue().start();
  new VenueGroups().start();
  new ApplicationSettings().start();
  new OfflinePayments().start();

  new VenueAdminInvitation().start();
  new Invitation().start();
});
