window.prepareGallery = () => {
  const preloadImage = (el, src, callback) => {
    let imageBox = el.find('.image-box')
    let img = new Image();
    img.src = src;

    const assignBackground = () => {
      imageBox.css('background-image', 'url(' + src + ')')
      if (callback) callback();
    };

    img.addEventListener('load', assignBackground, false);
    window.addEventListener("resize", assignBackground);
  }

  $.each($('.main-photo li.index-0'), (index, el) => {
    preloadImage($(el), $(el).data('src'), () => {
      $.each($('.main-photo li').not('.index-0'), (index2, el2) => {
        preloadImage($(el2), $(el2).data('src'));
      })
    })
  });

  $.each($('.thumb-photo li').not('.hide'), (index, el) => {
    preloadImage($(el), $(el).data('thumb'))
  });
}