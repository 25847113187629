$(document).ready(function(){
  function checkWidth() {
    const windowsize = $(window).width();
    const namesize =  $("#dLabel").width()

    if (windowsize <= 1180 && windowsize >= 900 && namesize > 200) {
      $(".user_avatar").css("display", "none");
      $(".user_name").css("max-width", "180px")
    }
  }
  checkWidth();

  $(window).resize(checkWidth);
})