import { Controller } from "@hotwired/stimulus";
import axios from "axios";

class OrderMenuButtonController extends Controller {
  click(event) {
    const url = event.target.dataset.url;

    const ulDropDown = this.uL();

    if (ulDropDown === undefined) {
      this.fetchMenu(url)
    } else {
      ulDropDown.remove();
    }
  }

  uL() {
    return this.element.querySelectorAll('ul.dropdown-menu')[0];
  }

  fetchMenu(url) {
    axios.get(url).then((response) => {
      this.element.innerHTML += response.data;
      this.uL().style.display = 'inherit';

      const newUlDropDown = this.uL();

      document.addEventListener('click', (event) => {
        if (!this.element.contains(event.target)) newUlDropDown.remove();
      });

      const items = newUlDropDown.querySelectorAll('a');

      items.forEach(item => {
        item.addEventListener('click', () => setTimeout(() => newUlDropDown.remove(), 100))
      });
    })
  }
}

export default OrderMenuButtonController;
