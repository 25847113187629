import DatatableAutoscroll from "../../helpers/datatable_autoscroll";

(function() {
  $(function() {
    var $admin_customers_modal, customers_table_params, gerCustomersUrl, onReDraw, orderDateFormat, reloadData, showModal;

    if ($('body.admin_venue_customers_index').length < 1) {
      return;
    }

    console.log('Admin::Customer');

    orderDateFormat = 'D, dd M yyyy';

    $("input[name='filter[start_date]'], input[name='filter[end_date]']").datepicker({
      format: orderDateFormat,
      autoclose: true,
      endDate: '+0d'
    });

    gerCustomersUrl = function() {
      return $('#customers-table').data('source') + '?' + $("form#new_filter").serialize();
    };

    customers_table_params = {
      order: [[0, 'asc']],
      processing: true,
      serverSide: true,
      searching: false,
      lengthChange: false,
      pageLength: 30,
      ajax: gerCustomersUrl(),
      pagingType: 'full_numbers',
      fnInitComplete: function(oSettings) {
        new DatatableAutoscroll(oSettings);
      },
      fnDrawCallback: function(oSettings) {
        var $table, $table_wrapper, show, titles;
        $table_wrapper = $(oSettings.nTableWrapper);
        show = oSettings._iDisplayLength <= oSettings.fnRecordsDisplay();
        $table_wrapper.find('.dataTables_paginate').toggle(show);
        $table_wrapper.find('.dataTables_info').toggle(show);
        $table = $table_wrapper.find('table');
        titles = _.map($table.find('thead th'), function(x, y) {
          return x.innerText;
        });
        return _.each($table.find('tr'), function(row) {
          return _.each($(row).find('td'), function(cell, index) {
            return $(cell).attr('data-title', titles[index]);
          });
        });
      }
    };

    $('#customers-table.admin_side').dataTable($.extend({}, customers_table_params, {
      order: [[0,'asc'], [2, 'asc'], [4, 'asc']],
      columnDefs: [
        {
          targets: [1],
          orderable: false
        }, {
          targets: [8,9],
          orderable: false
        }, {
          targets: [5],
          orderSequence: ['desc', 'asc']
        }, {
          targets: [6],
          orderSequence: ['desc', 'asc']
        }, {
          targets: [7],
          orderSequence: ['desc', 'asc']
        }
      ]
    }));
    onReDraw = function() {
      return $(this).DataTable().column(4).visible($('#filter_venue_id').val() === '');
    };
    $('#customers-table').on('draw.dt', onReDraw);
    reloadData = function(change_page) {
      var ajax;
      ajax = $('#customers-table').DataTable().ajax;
      ajax.url(gerCustomersUrl());
      return ajax.reload(null, change_page);
    };
    $('#new_filter .filter_time_period input[type=radio]').on('change', function(e) {
      var checked, checked_value;
      reloadData(true);
      checked = $('#new_filter .filter_time_period input[type=radio]:checked');
      checked_value = checked.val();
      return $('.custom_dates').toggleClass('hidden', checked_value !== 'custom');
    });

    $('#new_filter .filter_start_date, #new_filter .filter_end_date, #new_filter .filter_venue_id select').on('change', function() {
      return reloadData(true);
    });

    $('#new_filter .filter_terms input').on('keyup', function(e) {
      return reloadData(true);
    });

    $('#new_filter .filter_customers_by_activity input[type=radio]').on('change', function(e) {
      return reloadData(true);
    });

    $admin_customers_modal = $('#admin_customers_modal');

    showModal = function(link) {
      return $.get(link, function(data) {
        $admin_customers_modal.find('.modal-content').empty().append(data);
        return $admin_customers_modal.modal('show');
      });
    };

    $(document).on('click', '.dropdown-menu li a.open_customer_modal', function(e) {
      e.preventDefault();
      if ($(this).parent('li').hasClass('disabled')) {
        return;
      }
      return showModal($(this).attr('href'));
    });

    $(document).on('click', '.dropdown-menu li a.open_customer_venue_linkings_modal', function(e) {
      e.preventDefault();
      return showModal($(this).attr('href'));
    });

    $(document).on('click', '#admin_customers_modal form [type=submit]', function(e) {
      var $form, url;
      e.preventDefault();
      $form = $(this).closest('form');
      url = $form.attr('action');
      return $.ajax({
        type: "POST",
        url: url,
        data: $form.serialize(),
        success: function(data) {
          var reload;
          reload = $admin_customers_modal.find('form').data('reload');
          $admin_customers_modal.modal('hide');
          if (reload) {
            if ($('#customers-table').length > 0) {
              return reloadData(false);
            } else {
              return location.reload();
            }
          }
        },
        error: function(data) {
          return $admin_customers_modal.find('.modal-content').empty().append(data.responseText);
        }
      });
    });
  });

}).call(this);
