import axios from 'axios';

const openCustomMessagesPreviewModal = (target) => {
  const template = target.data('template');
  const form = $('#new_booking_custom_message');
  const params = form.serializeObject();

  axios
    .get(`/admin/booking_custom_message/${template}/preview`, { params: params })
    .then(response => {
      const modal = $('#admin_conversations_preview');
      modal.find('.modal-content').empty().append(response.data)
      modal.modal('show')
    })
    .catch((error) => {
      Helper.flash_message('error', error.response.data.error)
    });
}

$(document).on('click', '#preview-modal-close', function(e) {
  $('#admin_conversations_preview').modal('hide');
});

const openCustomMessagesModal = (e) => {
  axios
    .get('/admin/booking_custom_message', { params: {
        custom_message_type: e.find('#custom_message_type').val()
      }})
    .then(response => {
      sessionStorage.removeItem('custom_messages_data')
      $('#admin_conversations_modal').find('.modal-content').empty().append(response.data)

      $('#admin_conversations_modal').find("label[for='booking_custom_message_body']").text(e.find('#custom_message_label').val())
      $('#admin_conversations_modal').find('#custom_message_title').text(e.find('#custom_message_title').val())
      $('#admin_conversations_modal').find('#booking_custom_message_custom_message_type').val(e.find('#custom_message_type').val())

      const venue_id = $('li.inbox_conversation_item.selected').find('a').attr('data-venue')
      if (_.isEmpty(venue_id)) return

      $('#admin_conversations_modal').modal('show')
      $('#booking_custom_message_venue_id').val(venue_id)
      $('#booking_custom_message_venue_id').trigger('change')
    })
    .catch((error) => {
      Helper.flash_message('error', error.response.data.error)
    });
}

const checkEnabledAll = () => {
  const storage = (JSON.parse(sessionStorage.getItem('custom_messages_data')) || {})
  const keys = Object.keys(storage)
  const values = Object.values(storage)
  const option_length = $('#booking_custom_message_venue_id option').length - 1
  const index = keys.indexOf('all-venues');
  if (index > -1) {
    values.splice(index, 1);
  }
  const enabled_values = values.map((o) => o.enabled)
  return (enabled_values.length == option_length &&  enabled_values.indexOf(false) == -1)
}

const getCustomMessagesDataJson = () => {
  axios
    .get('/admin/booking_custom_message/edit.json', { params: {
        venue_id: $('#booking_custom_message_venue_id').val(),
        custom_message_type: $('#booking_custom_message_custom_message_type').val()
      }})
    .then(response => {
      const storage_data = (JSON.parse(sessionStorage.getItem('custom_messages_data')) || {})[$('#booking_custom_message_venue_id').val()]
      const body = storage_data ? storage_data['body'] : response.data.body || ''
      const enabled = storage_data ? storage_data['enabled'] : response.data.enabled

      $('#admin_conversations_modal').find('#booking_custom_message_body').val(body)
      $('#admin_conversations_modal').find('#booking_custom_message_enabled').prop('checked', enabled)
      if ($('#booking_custom_message_venue_id').val() == ('all-venues') && enabled == false) {
        $('#admin_conversations_modal').find('#booking_custom_message_enabled').prop('checked', checkEnabledAll())
      }
    })
    .catch((error) => {
      Helper.flash_message('error', error.response.data.error)
    });
}

const changeCustomMessagesData = () => {
  const value = JSON.parse(sessionStorage.getItem('custom_messages_data') || '{}')

  value[$('#booking_custom_message_venue_id').val()] = {
    custom_message_type: $('#booking_custom_message_custom_message_type').val(),
    enabled: $('#booking_custom_message_enabled').is(":checked"),
    body: $('#booking_custom_message_body').val()
  }

  sessionStorage.setItem('custom_messages_data', JSON.stringify(value));
}

const updateSubmenu = (e) => {
  axios
    .get('/admin/booking_custom_message/submenu.json', { params: {
        venue_id: e.find('a').attr('data-venue'),
      }})
    .then(response => {
      $('.custom-message-user_order_request_created .toggle-btn input').prop('checked', response.data.user_order_request_created || false)
      $('.custom-message-user_order_accepted .toggle-btn input').prop('checked', response.data.user_order_accepted || false)
      $('.custom-message-user_order_completed .toggle-btn input').prop('checked', response.data.user_order_completed || false)
      $('.custom-message-message_auto_response .toggle-btn input').prop('checked', response.data.message_auto_response || false)
      $('#auto-signature .toggle-btn input').prop('checked', response.data.enabled_signature || false)
    })
    .catch((error) => {
      Helper.flash_message('error', error.response.data.error)
    });
}

const saveCustomMessagesData = () => {
  changeCustomMessagesData()

  const bookingCustomMessages = JSON.parse(sessionStorage.getItem('custom_messages_data') || {});

  Object.keys(bookingCustomMessages).forEach(key => {
    if (bookingCustomMessages[key].body) {
      bookingCustomMessages[key].body = bookingCustomMessages[key].body.replace(/\n/g, '\r\n');
    }
  });

  axios
    .patch('/admin/booking_custom_message', { booking_custom_messages: bookingCustomMessages })
    .then(response => {
      sessionStorage.removeItem('custom_messages_data')
      $('#admin_conversations_modal').modal('hide')
      updateSubmenu($('li.inbox_conversation_item.selected'))
    })
    .catch((error) => {
      Helper.flash_message('error', error.response.data.error)
    });
}

$(document).on('click', '.custom-message-user_order_request_created,  ' +
  '.custom-message-user_order_accepted,  ' +
  '.custom-message-user_order_completed,  ' +
  '.custom-message-message_auto_response', function(e) {
  e.preventDefault()
  openCustomMessagesModal($(this))
});

$(document).on('click', '#preview_custom_message', function(e) {
  e.preventDefault();
  openCustomMessagesPreviewModal($(e.target));
});


$(document).on('change', '#booking_custom_message_venue_id', function(e) {
  e.preventDefault()
  getCustomMessagesDataJson()
});

$(document).on('change', '#booking_custom_message_enabled, #booking_custom_message_body', function(e) {
  e.preventDefault()
  changeCustomMessagesData()
});

$(document).on('click', '#save_custom_message', function(e) {
  e.preventDefault()
  saveCustomMessagesData()
});

$(document).on('click', 'li.inbox_conversation_item', function(e) {
  e.preventDefault()
  updateSubmenu($(this))
});