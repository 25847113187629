  import { Controller } from "@hotwired/stimulus"
import axios from 'axios';
import NeoModal from "src/NeoModal";

export default class extends Controller {
  connect() {
    const { stripeIntentStatus } = this.element.dataset;

    if (stripeIntentStatus === 'requires_action') {
      this.showModal();
    }
  }

  update(e) {
    const button = e.target;
    if (button.disabled) return true;
    button.disabled = true;
    this.showModal(() => button.disabled = false );
  }

  showModal(callback) {
    const url = this.element.dataset.url;

    axios.get(url).then(response => {
      const modal = NeoModal.add(response.data);

      $(modal).on('hidden.bs.modal', () => {
        if (callback) callback();
      })

      modal.addEventListener('neo_modal:payment_success', (e) => {
        const { data } = e.detail;

        window.OrderLive.stop();

        if (data.add_show_notification) localStorage.showNotification = 'showNotification';
        Cookies.set('show_new_order_id', data.order_id);
        window.location.replace(data.redirect_link);
      })
    }).catch(() => {
      Helper.flash_message('error', 'Something went wrong');
      if (callback) callback();
    })
  }
}
