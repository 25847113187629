import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

export const FlashMessage = (message) => {
  console.log('nemmsa')

  toastr.options = {
    positionClass : 'toast-top-center',
    hideDuration: 500,
    showDuration: 300,
    timeOut: 4000
  }
  toastr.clear()
  return toastr.error(message)
}
