import React from "react";
import { OverlayTrigger, Popover } from 'react-bootstrap';

export default ({ title,
                  description,
                  pricing_calculation_type,
                  checked,
                  mandatory,
                  persons,
                  price_adjustment,
                  price_adjustment_reason,
                  showPriceAdjustment,
                  onChangeChecked,
                  onPriceAdjustmentChange,
                  onPriceAdjustmentReasonChange,
                  onChangePersons }) => {

  const handleChangeChecked = (e) => {
    const checked = e.target.checked;
    onChangeChecked(checked);
  }

  const handleChangePersons = (e) => {
    const value = e.target.value;
    onChangePersons(value);
  }

  return (
    <div className='item item-extra'>
      <div className='extra-info'>
        <label className='checkbox'>
          <input type='checkbox'
                 checked={checked}
                 disabled={mandatory}
                 onChange={handleChangeChecked}/>

          <span className='title'>{title}</span>

          {pricing_calculation_type === 'person' &&
            <input className='persons-input'
                   size={2}
                   type='text'
                   disabled={!checked}
                   value={persons}
                   onChange={handleChangePersons}/>
          }
        </label>
        {description !== null &&
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="right"
            overlay={<Popover id={`extra_#{id}`}>{description}</Popover>}>
            <div className='info-icon list-extra-description-icon'/>
          </OverlayTrigger>
        }
      </div>
      {showPriceAdjustment &&
        <div className='form-inline extra-adjusment'>
          <div className='form-group extra-price-adjustment-input'>
            <label className='control-label extra-pa-label-item'>Price adjustment (£)</label>
            <input
              className='form-control numeric decimal optional'
              value={price_adjustment || ''}
              onChange={e => onPriceAdjustmentChange(e.target.value)}
              type='number'
              disabled={!checked}
            />
          </div>
          <div className='form-group'>
            <label className='string optional control-label extra-pa-label-item'>Reason</label>
            <input
              className='form-control extra-pa-reason-input'
              value={price_adjustment_reason || ''}
              onChange={e => onPriceAdjustmentReasonChange(e.target.value)}
              disabled={!checked}
            />
          </div>
        </div>
      }
  </div>
  )
};
