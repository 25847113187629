import axios from "axios";
import NeoModal from "src/NeoModal";

class FileManager {
  init() {
    const tableRows = Array.from($('#file-manager-table tr[data-filterable="true"]'));
    let selectedVenue = $('select[name="venue_select"] option:selected').val();
    let docType = $('input[name="filter_attachments_dates"]:checked').val();
    const filterDocs = () => {
      tableRows.forEach((row) => {
        let venueName = selectedVenue;
        const venueFromRow = $(row).find('td[data-title="Venue"]')[0];

        if (venueFromRow) {
          venueName = venueFromRow.textContent.trim();
        }

        if (docType == 'all' && selectedVenue == 'all') {
          $(row).show();
        } else if (docType == 'all' && selectedVenue == venueName) {
          $(row).show();
        } else if (docType == 'all' && selectedVenue != venueName) {
          $(row).hide();
        } else if (docType == 'current' && selectedVenue == 'all') {
          if (!row.classList.contains('expired')) {
            $(row).show();
          } else {
            $(row).hide();
          }
        } else if (docType == 'current' && selectedVenue == venueName) {
          if (!row.classList.contains('expired')) {
            $(row).show();
          } else {
            $(row).hide();
          }
        } else {
          $(row).hide();
        }
      });
    };

    if (selectedVenue && docType) {
      filterDocs();
    }

    if ($('#filter_attachments_dates_current').is(':checked')) {
      $('.expired').hide();
    }

    $('input[name="filter_attachments_dates"]').change(() => {
      docType = $('input[name="filter_attachments_dates"]:checked').val();
      filterDocs();
    });

    $('select[name="venue_select"]').change((event) => {
      selectedVenue = event.target.selectedOptions[0].value;
      filterDocs();
    })

    $('.new-expired-date').datepicker({
      format: 'dd/mm/yyyy',
      autoclose: true,
      clearBtn: true,
      container: '.file-manager-modal-dialog'
    });

    $(document).on('click', '.add-file-btn', () => {
      $('#attachment_file').val('');
      $('#attachment_original_filename').val('');
      $('#attachment_expired_at').val('');
      $('#attachment_link_to_activity_report').prop('checked', false);
      $('#new-file-form').show();
    });

    $('.cancel-add-file').click(() => {
      $('#new-file-form').hide();
    });

    this.onEdit();
    this.onSave();
    this.onDelete();
    this.onCancel();
  }

  reload() {
    const documents_tab = $("li.active a[data-target='#documents']");
    const venue_questions_tab = $("li.active a[data-target='#venue_questions']");

    if (documents_tab.length > 0) window.loadOrderTab(documents_tab, true, true);
    if (venue_questions_tab.length > 0) window.loadOrderTab(venue_questions_tab, true, true);
  }

  onEdit() {
    $('#file-manager-table').on('click', '.edit-file-manager', (event) => {
      const $this = $(event.target);

      const row = $this.closest('tr');
      const nameInput = row.find('.editable-name input');
      const expirationInput = row.find('.editable-date input');
      const linkInput = row.find('.editable-link input');

      nameInput.prop('disabled', false);
      expirationInput.prop('disabled', false);
      expirationInput.datepicker({
        format: 'dd/mm/yyyy',
        autoclose: true,
        clearBtn: true,
        container: '.file-manager-modal-dialog'
      });
      linkInput.prop('disabled', false);

      row.find('.save').show();
      row.find('.edit').hide();
    });
  }

  onCancel() {
    $('.cancel-file-manager').click((event) => {
      const $this = $(event.target);

      const row = $this.closest('tr');
      const nameInput = row.find('.editable-name input');
      const expirationInput = row.find('.editable-date input');
      const linkInput = row.find('.editable-link input');

      nameInput.val(row.data('name'));
      nameInput.prop('disabled', true);
      expirationInput.val(row.data('date'));
      expirationInput.prop('disabled', true);
      linkInput.prop('checked', row.data('link-to-activity-report'));
      linkInput.prop('disabled', true);

      row.find('.save').hide();
      row.find('.edit').show();
    });
  }

  onSave() {
    $('.save-file-manager').click((event) => {
      const $this = $(event.target);

      const row = $this.closest('tr');
      const nameInput = row.find('.editable-name input');
      const expirationInput = row.find('.editable-date input');
      const linkInput = row.find('.editable-link input');

      $.ajax({
        url: $this.data('url'),
        method: 'PUT',
        data: {
          file_manager: {
            original_filename: nameInput.val(),
            expired_at: expirationInput.val(),
            link_to_activity_report: linkInput.is(':checked')
          }
        },

        success: () => {
          nameInput.prop('disabled', true);
          expirationInput.prop('disabled', true);
          linkInput.prop('disabled', true);

          row.data('name', nameInput.val());
          row.data('date', expirationInput.val());
          row.data('link_to_activity_report', linkInput.is(':checked'));
          row.find('.save').hide();
          row.find('.edit').show();

          this.reload();
        }
      });
    });
  }

  onDelete() {
    $('.delete-file-manager').click((event) => {
      const $this = $(event.target);
      const _self = this;

      const row = $this.closest('tr');
      const url = $this.data('url');
      const request_url = $this.data('requestUrl');

      axios.get(request_url).then((response) => {
        const modal = NeoModal.add(response.data);
        const saveBtn = modal.getElementsByClassName('btn-approve-delete')[0];

        saveBtn.addEventListener('click', () => {
          axios
            .delete(url)
            .then(() => {
              row.remove();
              _self.reload();
              $(modal).modal('hide');
            })
            .catch(() => Helper.flash_message('error', 'Something went wrong'))
        });
      })
    });
  }

  scrollIntoFileView() {
    const itemRow = document.getElementsByClassName('selected-document')[0];

    if (!itemRow) return

    itemRow.style.borderColor = 'transparent';
    itemRow.style.border = '2px solid';

    setTimeout(() => {
      itemRow.previousElementSibling.scrollIntoView(true);
    }, 200);
  }
}

export default FileManager;
