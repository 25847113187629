import axios from "axios";

window.OrderLive = (function() {
  var interval = 1;
  var timer = null;

  function prepareText(duration, text) {
    if (!text) text = 'You have #time to complete this stage of the booking process'

    var dH = duration.days() * 24;
    var h = duration.hours() + dH;
    var m = duration.minutes();
    var s = duration.seconds();

    var time = [(h >= 1 ? h : null) , (m > 9 ? m : '0' + m), (s > 9 ? s : '0' + s)]
                 .filter(function(el) { return el != null })
                 .join(':')

    return text.replace('#time', time);
  }

  function start() {
    var $el = $('#checkout_countdown');

    if ($el.length === 0) return null;

    if (timer) clearInterval(timer);

    const seconds = $el.data('durationSeconds');
    const text = $el.data('text');
    const redirectUrl = $el.data('redirectUrl');

    let duration = moment.duration({ seconds: seconds });

    $el.html(prepareText(duration, text)).removeClass('invisible');

    let iterations = 0;

    timer = setInterval(() => {
      iterations++;

      if (duration.asSeconds() <= 0) {
        clearInterval(timer);
        window.location.href = redirectUrl || '/';
      } else {
        duration = moment.duration(duration.asSeconds() - interval, 'seconds');
        $el.data('duration-seconds', duration.asSeconds());

        $el.html(prepareText(duration, text));
        $el.removeClass('invisible');

        if (duration.asSeconds() % 15 === 0 || iterations === 1) {
          sync();
        }
      }
    }, 1000);

    return timer;
  }

  function restart() {
    if (timer) clearInterval(timer);
    start();
  }

  function stop() {
    if (timer) clearInterval(timer);
  }

  function sync(callback) {
    const el = $('#checkout_countdown');

    if (el.length === 0) {
      if (typeof callback === 'function') {
        callback();
      }

      return;
    }

    const elData = el.data();

    if (elData.hasOwnProperty('autoUpdatePath')) {
      axios.get(elData.autoUpdatePath).then((res) => {
        const currentDurationSeconds = elData.durationSeconds;

        if (Math.abs(currentDurationSeconds - res.data.duration_seconds) > 2) {
          el.data('duration-seconds', res.data.duration_seconds);
          restart();
        }

        if (typeof callback === 'function') {
          callback();
        }
      });
    } else {
      if (typeof callback === 'function') {
        callback();
      }
    }
  }

  return {
    start: start,
    restart: restart,
    sync: sync,
    stop: stop
  };
})();

$(document).ready(OrderLive.start);
