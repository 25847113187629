import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Button } from "react-bootstrap";
import ModalCreate from "../ModalCreate/Index";
import ModalEdit from "../ModalEdit/Index";
import Column from "../../Reports/Columns";
import { useModal } from '../useModal';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import { snakeToTitle, singularize } from '@/helpers/strings'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import IntervalTitleDecorator from '../helpers/interval_title_decorator'
import classnames from "classnames";
import { FlashMessage } from "../../MainSearch/FlashMessage";

function Index({ location, history }) {
  const [reportsLoaded, setReportsLoaded] = useState(false);
  const [reportSchedules, setReportSchedules] = useState([]);
  const [reportTypes, setReportTypes] = useState([]);
  const [currentUserIsRo, setCurrentUserIsRo] = useState([]);

  const updateReportSchedules = useCallback(()=> {
    axios
      .get('/admin/reports/schedules.json')
      .then((response) => {
        setReportSchedules(response.data.reportSchedules);
        setReportTypes(response.data.reportTypes);
        setCurrentUserIsRo(response.data.currentUserIsRo);
        setReportsLoaded(true);
      });
  }, []);

  const [modalCreate, showModalCreate] = useModal(ModalCreate, updateReportSchedules);
  const [modalEdit, showModalEdit] = useModal(ModalEdit, updateReportSchedules);

  const handleDelete = (scheduleId) => {
    if (confirm('Are you sure?')) {
      axios
        .delete(`/admin/reports/schedules/${scheduleId}.json`)
        .then((response) => {
          updateReportSchedules();
        });
    }
  };

  useEffect(() => {
    updateReportSchedules();
  }, [])

  const reportScheduleInfoPopover = (
    <Popover id="popover-positioned-scrolling-left" title="">
      <p>
        Any reports scheduled will be sent via email based on the filters you have
        selected.
      </p>
      <p>
        As an example, you can select for the Activity Report to be
        emailed to you weekly on a Sunday for the upcoming week
        {currentUserIsRo && <span>.</span>}
        {!currentUserIsRo &&
          <span>
            , or the Income per Facility Report to be sent to you monthly for the Current Academic Year.
          </span>
        }
      </p>
    </Popover>
  )

  const showNotice = () => {
    FlashMessage(
      "You have the reached the maximum number of scheduled reports. " +
      "You will need to remove an existing one before being able to " +
      "create a new one."
    )
  }

  const disabled = !reportsLoaded || reportSchedules.length >= 10;

  const addButtonClassNames = useMemo(() =>
    classnames(
    'btn add-schedule-button',
      { 'btn-primary': !disabled },
      { 'disabled': disabled }
    ), [disabled])

  return (
    <div>
      {modalCreate}
      {modalEdit}

      <div className=''>
        <h1>
          Scheduled Reports
          <OverlayTrigger
            container={this}
            placement="right"
            overlay={reportScheduleInfoPopover}
          >
            <div className="info-icon" data-original-title="" title=""/>
          </OverlayTrigger>

          <div className="btn-group pull-right">
            <button className={addButtonClassNames} onClick={(e) => disabled ? showNotice(e) : showModalCreate(e)}>
              Add report schedule
            </button>
          </div>
        </h1>
      </div>

      <div className='report-schedules-body'>
        <table className='table table-bordered'>
          <thead className='scheduled-reports-table-header'>
            <tr>
              <th>Type</th>
              <th>Interval</th>
              <th>Export</th>
              <th>Start date</th>
              <th>Time</th>
              <th>Days</th>
              <th>Venue</th>
              <th>Users</th>
              <th>Date created</th>
              <th>Created by</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
          {reportSchedules.map(item =>
            <tr key={item.id}>
              <Column.Text>
                {snakeToTitle(singularize(item.key))}
              </Column.Text>
              <Column.Text>
                {IntervalTitleDecorator(item.reportInterval)}
              </Column.Text>
              <Column.Text>
                {item.exportType}
              </Column.Text>
              <Column.Date>
                {item.startDate}
              </Column.Date>
              <Column.Text>
                {moment(item.reportTime).format('HH:mm')}
              </Column.Text>
              <Column.Text>
                {item.weekDaysString}
              </Column.Text>
              <Column.Text>
                {item.venueTitle}
              </Column.Text>
              <Column.Text>
                {item.userNamesString}
              </Column.Text>
              <Column.Date>
                {item.createdAt}
              </Column.Date>
              <Column.Text>
                {item.createdBy}
              </Column.Text>
              <Column.Text>
                <div className='action_block'>
                  <Button disabled={!item.allowedToEdit} onClick={() => showModalEdit({ id: item.id, reportTypes: reportTypes })}>Edit</Button>
                  <Button disabled={!item.allowedToEdit} onClick={() => handleDelete(item.id)}>Delete</Button>
                </div>
              </Column.Text>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default withRouter(Index);
