import React, { useEffect, useState, useRef, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from "./Form";
import axios from "axios";
import ErrorBoundary from "../ErrorBoundary";

export default ({ id, show, setShow, onCompleted, options }) => {
  const [reportType, setReportType] = useState(null);
  const [searchFilters, setSearchFilters] = useState(null);
  const [startDate, setStartDate] = useState()
  const [weekDays, setWeekDays] = useState([]);
  const [disabledConfirm, setDisabledConfirm] = useState(false);
  const [exportType, setExportType] = useState()
  const [reportTime, setReportTime] = useState(moment('20:00', 'HH:mm'))
  const [validationErrors, setValidationErrors] = useState({})
  const [availableUsers, setAvailableUsers] = useState([])
  const [ccUsers, setCcUsers] = useState([])
  const [selectedVenueId, setSelectedVenueId] = useState()
  const [showCcUsersSelector, setShowCcUsersSelector] = useState(false)
  const [currentUser, setCurrentUser] = useState()
  const [reportInterval, setReportInterval] = useState()
  const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

  // const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (show === true) {
      axios
        .get(`/admin/reports/schedules/${id}.json`)
        .then((response) => {
          setReportType(options.reportTypes.find(item => item.key == response.data.report));
          if (response.data.filters !== null) {
            setSearchFilters(response.data.filters);
          }
          setStartDate(moment(response.data.startDate).toDate())
          setWeekDays(response.data.weekDays.map(dayNumber => { return { label: dayNames[dayNumber], value: dayNumber} }))
          setExportType(response.data.exportType)
          setReportTime(moment(response.data.reportTime))
          setCcUsers(response.data.ccUsers)
          setReportInterval(response.data.reportInterval)
          if(response.data?.filters?.venueId) {
            setSelectedVenueId(response.data.filters.venueId)
            axios
              .get('/admin/reports/schedules/venue_users.json', {params: { venue_id: response.data.filters.venueId }})
              .then((response) => {
                setAvailableUsers(response.data.venueUsers || [])
                setCurrentUser(response.data.currentUser)
              })
          }
        });
    } else {
      setReportType(undefined);
      setSearchFilters({});
      setStartDate('')
      setWeekDays([]);
      setValidationErrors({})
      setAvailableUsers([])
      setCcUsers([])
      setSelectedVenueId()
    }
  }, [show])

  const handleCreate = () => {
    setDisabledConfirm(true);

    const formData = {
      report_schedule: {
        report: reportType.key,
        filters: searchFilters,
        start_date: startDate,
        week_days: weekDays.map(item => item.value),
        export_type: exportType,
        report_time: reportTime,
        cc_users: ccUsers.map(user => delete user['readOnly?'] && user),
        report_interval: reportInterval
      }
    };

    axios
      .put(`/admin/reports/schedules/${id}.json`, formData)
      .then((response) => {
        setShow(false);
        onCompleted();
        setDisabledConfirm(false)
      })
      .catch((error) => {
        if(error?.response?.data?.errors) {
          setValidationErrors(error.response.data.errors)
        }
        setDisabledConfirm(false)
      });
  }

  const handleFiltersChange = (newValue) => {
    const newVenueId = newValue['venueId']
    const isNotVenueGroup = (typeof newVenueId === 'number')
    setSelectedVenueId(newVenueId)
    setShowCcUsersSelector(isNotVenueGroup)
    if(!isNotVenueGroup) {
      setCcUsers([])
      setAvailableUsers([])
    }
    if(isNotVenueGroup && newVenueId !== selectedVenueId) {
      setCcUsers([currentUser])
      setAvailableUsers([])
      axios
        .get('/admin/reports/schedules/venue_users.json', {params: { venue_id: newVenueId }})
        .then((response) => { setAvailableUsers(response.data.venueUsers || []) })
    }
    setSearchFilters(newValue)
  }

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Report Schedule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorBoundary>
          <Form
            reportType={reportType}
            setReportType={setReportType}
            searchFilters={searchFilters}
            handleFiltersChange={handleFiltersChange}
            startDate={startDate}
            setStartDate={setStartDate}
            weekDays={weekDays}
            setWeekDays={setWeekDays}
            exportType={exportType}
            setExportType={setExportType}
            reportTime={reportTime}
            setReportTime={setReportTime}
            validationErrors={validationErrors}
            ccUsers={ccUsers}
            availableUsers={availableUsers}
            setCcUsers={setCcUsers}
            showCcUsersSelector={showCcUsersSelector}
            reportInterval={reportInterval}
            setReportInterval={setReportInterval}
          />
        </ErrorBoundary>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button className='btn-primary' disabled={disabledConfirm} onClick={handleCreate}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  )
};
