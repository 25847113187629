import React, { useEffect, useState, useMemo } from 'react';
import axios from "axios";
import Filter from "../../MegaFilter/Index"
import DaysSelector from '../shared/days_selector'
import DateInput from 'src/components/DateInput'
import TimePicker from 'rc-time-picker'
import ValidationError from '../shared/validation_error'
import Select from "react-select";
import IntervalTitleDecorator from '../helpers/interval_title_decorator'

export default (
  { reportType,
    setReportType,
    searchFilters,
    handleFiltersChange,
    startDate,
    setStartDate,
    weekDays,
    setWeekDays,
    exportType,
    setExportType,
    reportTime,
    setReportTime,
    validationErrors,
    ccUsers,
    setCcUsers,
    availableUsers,
    showCcUsersSelector,
    reportInterval,
    setReportInterval,
    currentUser
  }) => {
  const [reportTypes, setReportTypes] = useState([]);
  const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, index) => {
    return { label: day, value: index }
  })

  useEffect(() => {
    axios
      .get('/admin/reports/schedules.json')
      .then((response) => {
        setReportTypes(response.data.reportTypes);
        setReportType(response.data.reportTypes[0])
      });
  }, [])

  useEffect(() => {
    setExportType(reportType?.availableExportOptions[0])

    if(!reportType?.availableTimePeriods?.includes(reportInterval)) {
      setReportInterval('today')
    }

    if(!reportType?.roUsersAllowed && currentUser) { setCcUsers([currentUser]) }
  }, [reportType])

  const availableReportIntervals = useMemo(() => {
    return reportType?.availableTimePeriods?.filter(period => !['last_month', 'custom', 'last_academic_year'].includes(period) )
  }, [reportType])

  const availableCcUsers = useMemo(() => {
    if(reportType?.roUsersAllowed) { return availableUsers }
    return availableUsers.filter(user => !user['readOnly?'])
  }, [reportType, availableUsers])

  const onSelectReportType = (event) => {
    setReportType(reportTypes.find(item => item.key == event.target.value));
  }

  return (
    <form className="simple_form" action="#" method="post" id='report-schedules-form'>
      <div className="row vert-offset-bottom-15">
        <div className='col-md-4 form-item'>
          <label>Type</label>
          <select name="report" className="form-control select required" value={reportType && reportType.key} onChange={onSelectReportType}>
          {reportTypes.map(item =>
            <option key={item.key} value={item.key}>{item.title}</option>
          )}
          </select>
        </div>
        <div className='col-md-4 form-item'>
          <label>Interval</label>
          <select className="form-control select required" value={reportInterval} onChange={e => setReportInterval(e.target.value)}>
            { availableReportIntervals?.map(item =>
              <option key={item} value={item}>{IntervalTitleDecorator(item)}</option>)
            }
          </select>
        </div>
        <div className='col-md-4 form-item'>
          <label>Export</label>
          {exportType}
          <select name="reportExportType" className="form-control select required" value={exportType} onChange={e => setExportType(e.target.value)}>
            {reportType?.availableExportOptions.map(exportOption =>
              <option key={exportOption} value={exportOption}>{exportOption}</option>
            )}
          </select>
        </div>
        <div className='col-md-4' />
        <div className='col-md-8'>
          <ValidationError error={validationErrors['only_csv_export_type_allowed']} message='*Please select the CSV export for a Venue Group.' />
        </div>
      </div>
      <div className="row vert-offset-bottom-15">
        <div className='col-md-4 start-date-input form-item'>
          <label>Start date to receive emails</label>
          <DateInput onChange={setStartDate} value={startDate} />
        </div>
        <div className='col-md-4 form-item'>
          <label>Time to receive emails</label>
          <TimePicker
            value={reportTime}
            onChange={setReportTime}
            minuteStep={15}
            allowEmpty={false}
            showSecond={false}
          />
        </div>
        <div className='col-md-4 form-item'>
          <label>Days to receive emails</label>
          <DaysSelector
            className='weekdays-selector'
            value={weekDays}
            isLoading={false}
            isDisabled={false}
            options={dayNames}
            onChange={setWeekDays}
          />
        </div>
      </div>
      <div className="row vert-offset-bottom-15">
        <div className='col-md-12 reports-section form-item'>
          {reportType && <Filter onUpdated={handleFiltersChange} item={reportType} options={searchFilters} />}
        </div>
        {showCcUsersSelector && availableCcUsers.length > 1 &&
          <div className='col-md-12 form-item'>
            <label>Users to receive emails</label>
            <Select isMulti='true'
              getOptionLabel={opt => opt.name}
              getOptionValue={opt => opt.id}
              options={availableCcUsers}
              value={ccUsers}
              onChange={setCcUsers}
            />
            <ValidationError error={validationErrors['cc_users']} message='*At least 1 user must be selected' />
            <ValidationError error={validationErrors['ro_users']} message='*Users with RO access disallowed to receive this report type' />
          </div>
        }
      </div>

      <ValidationError error={validationErrors['base']} />
    </form>
  );
};
